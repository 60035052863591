<template>
    <div>
        <div class="page-container">
            <div class="search" v-if="!$route.path.startsWith('/search')">
                <div class="input-outer">
                    <label :class="is_focus ? 'input-container input-container-focus' : 'input-container'">
                        <div style="display: flex; width: 100%;">
                            <SearchSvg class="input-icon-search" />
                            <ClearSvg class="input-icon-clear" @click.native="clear" v-show="is_focus && key_word" />
                            <input class="input" :placeholder="placeholder" v-model="key_word" @keyup.enter="search"
                                @focus="focus" @input="getSearchSuggestion" @blur="blur" ref="input" id="searchInput" />
                        </div>
                        <div v-show="is_focus">
                            <div @click="selectSuggestion(index)"
                                :class="index == search_suggestion_list.length - 1 ? (index == suggestion_index ? 'search-suggestion-item search-suggestion-item-last search-suggestion-item-selected ' : 'search-suggestion-item  search-suggestion-item-last') : (index == suggestion_index ? 'search-suggestion-item search-suggestion-item-selected' : 'search-suggestion-item')"
                                v-for="(item, index) in search_suggestion_list" :key="index">{{ item.before }}<span
                                    class="search-suggestion-item-bold">{{ item.matched }}</span>{{ item.after }}</div>
                        </div>
                    </label>
                </div>
            </div>

            <div style="height: 30px" v-if="$route.path.startsWith('/search')"></div>

            <Swiper :showIndicator="false" interval="3500" v-if="banner_list.length > 0" class="banner-container">
                <Slide v-for="(banner, index) in banner_list" :key="index">
                    <img :src="banner.banner_image" class="banner-image" @click="onTapBanner(banner)">
                </Slide>
            </Swiper>
        </div>


        <div class="one-container" v-if="!$route.path.startsWith('/search')">
            <One :show_border="false" :type="item.type" :data="item.data" v-for="(item, index) in one_list" :key="index" />
        </div>

        <div class="page-container">
            <div style="margin-top: 1rem;">
                <div class="info-links">
                    <p @click="nav2About">{{ $t('RightRecommend.about') }}</p>
                    <p @click="nav2Agreement">{{ $t('RightRecommend.agreement') }}</p>
                    <p @click="nav2Privacy">{{ $t('RightRecommend.privacy') }}</p>
                </div>
                <div class="info-links-no-hover">
                    <p>© 2024 Triple Uni</p>
                </div>
            </div>

        </div>
    </div>
</template>
      
<script>
import request from '../utils/request.js'
import { localizeTopic, localizeString } from '../utils/localize';

import One from './One.vue'
import notice from '../utils/notice.js'
import { Swiper, Slide } from 'vue-swiper-component'

import SearchSvg from './Icon/SearchSvg.vue'
import ClearSvg from './Icon/ClearSvg.vue'

export default {
    name: 'UniRightRecommend',
    components: { One, Swiper, Slide, SearchSvg, ClearSvg },
    created() {
        this.getPlaceholder()
        this.getPostByTopic()
        this.getBanner()
    },
    activated() {
        document.addEventListener('keydown', this.wathcKeyboard)
    },
    deactivated() {
        document.removeEventListener('keydown', this.wathcKeyboard)
    },
    data() {
        return {
            key_word: '',
            search_suggestion_list: [],
            suggestion_index: -1,
            suggestion_keyword: '',
            orginal_key_word: '',
            is_focus: false,
            banner_width: '0px',
            banner_height: '0px',
            placeholder: this.$t('MiddleSearch.placeholder'),
            one_list: [],
            banner_list: []
        }
    },
    methods: {
        getPostByTopic() {
            const user_school_label = localStorage.getItem('user_school_label')
            var post_topic = ''
            if (user_school_label == 'HKU') post_topic = 'ONE'
            if (user_school_label == 'CUHK') post_topic = '猫抓板'
            if (user_school_label == 'UST') post_topic = '小火鸟'
            request("/post/list/topic", {
                post_topic: post_topic,
                page: this.page
            })
                .then((res) => {
                    if (!res) return
                    res.one_list.forEach((one_item) => {
                        if (one_item.type == 'post') {
                            one_item.data.post_topic = localizeTopic(one_item.data.post_topic)
                        }
                    })
                    var post_count = 0
                    if (window.innerHeight > 700) post_count = 1
                    if (window.innerHeight > 1000) post_count = 2
                    if (window.innerHeight > 1300) post_count = 3
                    if (window.innerHeight > 1600) post_count = 4
                    this.one_list = res.one_list.slice(0, post_count)
                })
        },
        getBanner() {
            request("/info/banner")
                .then((res) => {
                    if (!res) return
                    this.banner_list = res.banner_list
                })
        },
        getPlaceholder() {
            request("/info/placeholder")
                .then((res) => {
                    if (!res) return
                    localizeString(res.placeholder).then((result) => {
                        this.placeholder = result
                    })
                })
        },
        onTapBanner(banner) {
            switch (banner.banner_type) {
                case 'article':
                    window.open(banner.article_link)
                    break;
                case 'post':
                    this.$router.push("/post/" + banner.uni_post_id)
                    break;
                case 'inner':
                    notice({
                        content: this.$t('Banner.inner')
                    })
                    break;
                case 'miniapp':
                    notice({
                        content: this.$t('Banner.miniapp')
                    })
                    break;
                case 'report2024':
                    if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
                        window.open('https://wxaurl.cn/wjOTp74MOPf')
                    } else {
                        this.$hevueImgPreview({
                            url: 'https://i.boatonland.com/report2024/assets/qrcode.png',
                            clickMaskCLose: true,
                            controlBar: false
                        })
                    }
                case 'none':
                default:
                    break;
            }
        },
        clear() {
            this.key_word = ''
            window.scrollTo(0, 0);
        },
        focus() {
            this.is_focus = true
            this.suggestion_index = -1
            this.getSearchSuggestion()
        },
        blur() {
            setTimeout(() => {
                this.is_focus = false
                this.suggestion_index = -1
            }, 100);
        },
        wathcKeyboard(e) {
            if (!this.is_focus) return
            let ev = document.all ? window.event : e
            if (ev.keyCode === 40) {
                if (this.suggestion_index >= this.search_suggestion_list.length - 1) return
                this.suggestion_index += 1
                if (this.suggestion_index == -1) {
                    this.key_word = ''
                } else {
                    this.key_word = this.search_suggestion_list[this.suggestion_index].orginal
                }
                setTimeout(() => {
                    var input = document.getElementById('searchInput')
                    input.focus()
                    input.setSelectionRange(input.value.length, input.value.length)
                }, 10);
            } else if (ev.keyCode === 38) {
                if (this.suggestion_index <= -1) return
                this.suggestion_index -= 1
                if (this.suggestion_index == -1) {
                    this.key_word = this.orginal_key_word
                } else {
                    this.key_word = this.search_suggestion_list[this.suggestion_index].orginal
                }
                setTimeout(() => {
                    var input = document.getElementById('searchInput')
                    input.focus()
                    input.setSelectionRange(input.value.length, input.value.length)
                }, 10);
            }
        },
        selectSuggestion(index) {
            this.key_word = this.search_suggestion_list[index].orginal
            this.search()
        },
        getSearchSuggestion() {
            this.is_focus = true
            this.orginal_key_word = this.key_word
            this.suggestion_index = -1
            request("/info/searchsuggestion", {
                key_word: this.key_word
            })
                .then((res) => {
                    if (!res) return
                    this.search_suggestion_list = []
                    res.search_suggestion_list.forEach((suggestion) => {
                        var parts = suggestion.split(new RegExp(this.key_word, "i"))
                        this.search_suggestion_list.push({
                            orginal: suggestion,
                            before: parts[0],
                            matched: this.key_word,
                            after: parts[1]
                        })
                    })
                })
        },
        search() {
            let key_word = this.key_word
            this.key_word = ''
            this.$router.push({
                path: '/search/' + key_word,
                query: {
                    method: 'refresh'
                }
            })
        },
        nav2About() {
            this.$router.push('/about')
        },
        nav2Agreement() {
            const user_school_label = localStorage.getItem('user_school_label')
            window.open('https://terms.tripleuni.com/' + user_school_label.toLowerCase() + '/agreement')
        },
        nav2Privacy() {
            const user_school_label = localStorage.getItem('user_school_label')
            window.open('https://terms.tripleuni.com/' + user_school_label.toLowerCase() + '/privacy')
        }
    }
}
</script>

<style scoped>
.page-container {
    width: calc(100% - 30px);
    padding: 0 15px;
}

.one-container {
    width: calc(100% - 15px);
    padding-right: 15px;
}

.search {
    font-size: 1.3rem;
    line-height: 1.5rem;
    font-weight: 600;
    padding: 0.1rem 0 1rem 0;
    position: sticky;
    top: 0;
    z-index: 99;
    background-color: var(--bg);
}

.input-outer {
    height: 50px;
}

.input-container {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--bg);
    margin: 15px 15px 0 15px;
    border: 0.1rem solid var(--border-grey);
    border-radius: 25px;
    outline: none;
    z-index: 100;
}

.input-container-focus {
    border: 0.1rem solid var(--main);
}

.input-icon-search {
    position: absolute;
    top: 0.8rem;
    left: 1rem;
    width: 1.4rem;
    height: 1.4rem;
}

.input-icon-clear {
    position: absolute;
    top: 0.9rem;
    right: 1.1rem;
    width: 1.4rem;
    height: 1.4rem;
    cursor: pointer;
}

.input {
    padding: 0.8rem 3rem 0.8rem 3rem;
    gap: 0.5rem;
    font-size: 1rem;
    color: var(--text);
    border: 0.1rem solid transparent;
    width: calc(100% - 6rem);
    background-color: transparent;
    border-radius: 25px;
}

.input:focus {
    outline: none;
    border: 0.1rem solid transparent;
}

.search-suggestion-item {
    font-size: 1rem;
    color: var(--text);
    margin: 0 0rem;
    padding: 0 3rem;
    font-weight: normal;
    border-top: 0.1rem solid var(--border-grey);
    line-height: 3rem;
    cursor: pointer;
}

.search-suggestion-item:hover {
    background-color: var(--border-grey);
}

.search-suggestion-item-selected {
    background-color: var(--border-grey);
}

.search-suggestion-item-last {
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.search-suggestion-item-bold {
    font-weight: 600;
}

.banner-container {
    margin: 0 15px 15px 15px;
    width: calc(100% - 30px);
    border-radius: 16px;
}

.banner-image {
    width: 100%;
    height: auto;
    border-radius: 16px;
    cursor: pointer;
}

.info-links {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 0 15px;
}

.info-links p {
    color: var(--text-grey);
    font-size: 0.8rem;
    line-height: 1.2rem;
    /* min-width: 5rem; */
    margin-bottom: 0;
    margin-top: 0.25rem;
    cursor: pointer;
}

.info-links-no-hover {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 0 15px;
}

.info-links-no-hover p {
    color: var(--text-grey);
    font-size: 0.8rem;
    line-height: 1.2rem;
    min-width: 5rem;
    margin-bottom: 0;
    margin-top: 0.25rem;
}

.info-links p:hover {
    text-decoration: underline;
}
</style>