export default function checkEmojiFromText(text) {
    const emojiMap = {
        '生日快乐': '🎂',
        'bitrhday': '🎂',
        '好想你': '✨',
        '爱你': '😘',
        '好龟': '🐢',
        '啊啊啊啊啊': '😫',
        '哈哈哈哈哈': '🤣',
        '新年快乐': '🎉'
    };

    for (const keyword in emojiMap) {
        if (text.includes(keyword)) {
            const emoji = emojiMap[keyword];
            startFallingEmojis(emoji);
        }
    }
}

function createFallingEmoji(emoji) {
    const item = document.createElement('div');
    item.classList.add('falling-item');
    item.innerText = emoji;
    item.style.left = Math.random() * 100 + 'vw';

    const animations = ['fall1', 'fall2', 'fall3'];
    const randomAnimation = animations[Math.floor(Math.random() * animations.length)];
    item.style.animation = `${randomAnimation} ${Math.random() * 2 + 4}s linear forwards`;

    document.body.appendChild(item);
    item.addEventListener('animationend', () => {
        item.remove();
    });
}

export function startFallingEmojis(emoji) {
    const interval = setInterval(() => createFallingEmoji(emoji), 100);
    setTimeout(() => {
        clearInterval(interval);
    }, 3000);
}