<template>
    <div class="container">
        <h1>扫码开启2024年度报告</h1>
        <div class="qrcode-wrapper">
            <img src="https://i.boatonland.com/report2024/assets/qrcode.png">
        </div>
    </div>
</template>

<script>
import { startFallingEmojis } from '../utils/emoji.js'

export default {
    created() {
        document.title = '扫码开启2024年度报告'
        if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
            window.location.href = 'https://wxaurl.cn/wjOTp74MOPf'
        } else {
            startFallingEmojis('🥳')
        }
    }
};
</script>

<style scoped>
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
}

.qrcode-wrapper {
    margin-top: 20px;
}

img {
    width: 250px;
    height: 250px;
}
</style>