import { render, staticRenderFns } from "./RightRecommend.vue?vue&type=template&id=767ece03&scoped=true&"
import script from "./RightRecommend.vue?vue&type=script&lang=js&"
export * from "./RightRecommend.vue?vue&type=script&lang=js&"
import style0 from "./RightRecommend.vue?vue&type=style&index=0&id=767ece03&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "767ece03",
  null
  
)

export default component.exports