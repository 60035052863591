<template>
  <div style="width: 100%;max-width: 100%;">
    <Navbar :title="title" :show_back="false" :sticky="false" />
    <div class="topic-bar">
      <div class="topic-tab">
        <button :class="(current_topic_index == index) ? 'topic-button active' : 'topic-button'"
          @click="switchTopic(index)" v-for="(topic, index) in topic_list" :key="index">{{ topic.topic_show }}</button>
      </div>
    </div>
    <Refresh :on-refresh="onRefresh">

      <div class="banner-outer" v-if="show_banner && banner_list.length > 0">
        <Swiper :showIndicator="false" interval="3500" class="banner-container">
          <Slide v-for="(banner, index) in banner_list" :key="index">
            <img :src="banner.banner_image" class="banner-image" @click="onTapBanner(banner)">
          </Slide>
        </Swiper>
      </div>

      <One :type="item.type" :data="item.data" v-for="(item, index) in one_list" :key="index" />
      <LoadMore :is_last="is_last" v-show="is_loading_more || is_last" />
    </Refresh>
  </div>
</template>
    
<script>
import request from '../utils/request.js'
import { localizeTopic } from '../utils/localize';

import One from './One.vue'
import LoadMore from './LoadMore.vue'
import Navbar from './Navbar.vue'
import { Swiper, Slide } from 'vue-swiper-component';

export default {
  name: 'UniMiddleHome',
  components: { One, LoadMore, Navbar, Swiper, Slide },
  created() {
    this.$loading.show()
    this.getTopic()
    this.getBanner()
    this.getPostPre()
  },
  activated() {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleScreenWidthChange)
    this.handleScreenWidthChange()
    if (this.$route.query.method == 'refresh') {
      this.$loading.show()
      window.scrollTo(0, 0)
      this.page = 0
      this.is_loading_more = true
      this.current_topic_index = 2
      this.getPostPre()
      this.$router.replace('/home')
    }
    const user_school_label = localStorage.getItem('user_school_label')
    if (user_school_label == "HKU") {
      this.title = this.$t('AppName.HKU');
      document.title = this.$t('MiddleHome.title') + " / " + this.$t('AppNameTitle.HKU')
    }
    if (user_school_label == "CUHK") {
      this.title = this.$t('AppName.CUHK');
      document.title = this.$t('MiddleHome.title') + " / " + this.$t('AppNameTitle.CUHK')
    }
    if (user_school_label == "UST") {
      this.title = this.$t('AppName.UST');
      document.title = this.$t('MiddleHome.title') + " / " + this.$t('AppNameTitle.UST')
    }
  },
  deactivated() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleScreenWidthChange)
  },
  data() {
    return {
      title: '',
      current_topic_index: 2,
      page: 0,
      topic_list: [
        {
          "topic": "👀",
          "topic_show": "👀"
        },
        {
          "topic": "🔥",
          "topic_show": "🔥"
        },
        {
          "topic": this.$t('MiddleHome.topic_all'),
          "topic_show": this.$t('MiddleHome.topic_all')
        },
        {
          "topic": localStorage.getItem('user_school_label'),
          "topic_show": localStorage.getItem('user_school_label')
        }
      ],
      banner_list: [],
      show_banner: false,
      one_list: [],
      is_last: false,
      is_loading_more: false,
      finishRefresh: null
    }
  },
  methods: {
    getTopic() {
      request("/info/gettopic")
        .then((res) => {
          if (!res) return
          this.topic_list = this.topic_list.concat(res.topic_list.map(topic => {
            return { topic: topic, topic_show: localizeTopic(topic) };
          }))
        })
    },
    getBanner() {
      request("/info/banner")
        .then((res) => {
          if (!res) return
          this.banner_list = res.banner_list
        })
    },
    switchTopic(index) {
      window.scrollTo(0, 0);
      this.page = 0
      this.current_topic_index = index
      this.$loading.show()
      this.getPostPre()
    },
    getPostPre() {
      if (this.current_topic_index == 0) {
        this.getPostByLatest()
      } else if (this.current_topic_index == 1) {
        this.getPostByHot()
      } else if (this.current_topic_index == 2) {
        this.getPostByAll()
      } else if (this.current_topic_index == 3) {
        this.getPostByLocal()
      } else {
        this.getPostByTopic()
      }
    },
    getPostByAll() {
      request("/post/list/all", {
        page: this.page
      })
        .then((res) => {
          this.$loading.hide()
          if (this.finishRefresh) this.finishRefresh()
          if (!res) return
          this.is_loading_more = false
          this.is_last = res.is_last
          res.one_list.forEach((one_item) => {
            if (one_item.type == 'post') {
              one_item.data.post_topic = localizeTopic(one_item.data.post_topic)
            }
          })
          if (this.page == 0) {
            this.one_list = res.one_list
          } else {
            this.one_list = this.one_list.concat(res.one_list)
          }
        })
    },
    getPostByLocal() {
      request("/post/list/local", {
        page: this.page
      })
        .then((res) => {
          this.$loading.hide()
          if (this.finishRefresh) this.finishRefresh()
          if (!res) return
          this.is_loading_more = false
          this.is_last = res.is_last
          res.one_list.forEach((one_item) => {
            if (one_item.type == 'post') {
              one_item.data.post_topic = localizeTopic(one_item.data.post_topic)
            }
          })
          if (this.page == 0) {
            this.one_list = res.one_list
          } else {
            this.one_list = this.one_list.concat(res.one_list)
          }
        })
    },
    getPostByTopic() {
      request("/post/list/topic", {
        post_topic: this.topic_list[this.current_topic_index].topic,
        page: this.page
      })
        .then((res) => {
          this.$loading.hide()
          if (this.finishRefresh) this.finishRefresh()
          if (!res) return
          this.is_loading_more = false
          this.is_last = res.is_last
          res.one_list.forEach((one_item) => {
            if (one_item.type == 'post') {
              one_item.data.post_topic = localizeTopic(one_item.data.post_topic)
            }
          })
          if (this.page == 0) {
            this.one_list = res.one_list
          } else {
            this.one_list = this.one_list.concat(res.one_list)
          }
        })
    },
    getPostByLatest() {
      request("/post/list/latest", {
        page: this.page
      })
        .then((res) => {
          this.$loading.hide()
          if (this.finishRefresh) this.finishRefresh()
          if (!res) return
          this.is_loading_more = false
          this.is_last = res.is_last
          res.one_list.forEach((one_item) => {
            if (one_item.type == 'post') {
              one_item.data.post_topic = localizeTopic(one_item.data.post_topic)
            }
          })
          if (this.page == 0) {
            this.one_list = res.one_list
          } else {
            this.one_list = this.one_list.concat(res.one_list)
          }
        })
    },
    getPostByHot() {
      request("/post/list/hot", {
        page: this.page
      })
        .then((res) => {
          this.$loading.hide()
          if (this.finishRefresh) this.finishRefresh()
          if (!res) return
          this.is_loading_more = false
          this.is_last = res.is_last
          res.one_list.forEach((one_item) => {
            if (one_item.type == 'post') {
              one_item.data.post_topic = localizeTopic(one_item.data.post_topic)
            }
          })
          if (this.page == 0) {
            this.one_list = res.one_list
          } else {
            this.one_list = this.one_list.concat(res.one_list)
          }
        })
    },
    onRefresh(finishRefresh) {
      this.finishRefresh = finishRefresh
      this.page = 0
      this.is_loading_more = true
      this.getPostPre()
    },
    loadMore() {
      if (this.is_loading_more) return;
      if (this.is_last) return;
      this.is_loading_more = true
      this.page = this.page + 1
      this.getPostPre()
    },
    handleScroll() {
      const scrollHeight = document.documentElement.scrollHeight
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const clientHeight = document.documentElement.clientHeight
      if (scrollTop + clientHeight >= scrollHeight - 1400) {
        this.loadMore()
      }
    },
    handleScreenWidthChange() {
      if (window.innerWidth <= 700) {
        this.show_banner = true
      } else {
        this.show_banner = false
      }
    },
    onTapBanner(banner) {
      switch (banner.banner_type) {
        case 'article':
          window.open(banner.article_link)
          break;
        case 'post':
          this.$router.push("/post/" + banner.uni_post_id)
          break;
        case 'inner':
          notice({
            content: this.$t('Banner.inner')
          })
          break;
        case 'miniapp':
          notice({
            content: this.$t('Banner.miniapp')
          })
          break;
        case 'report2024':
          if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
            window.open('https://wxaurl.cn/wjOTp74MOPf')
          } else {
            this.$hevueImgPreview({
                url: 'https://i.boatonland.com/report2024/assets/qrcode.png',
                clickMaskCLose: true,
                controlBar: false
            })
          }
        case 'none':
        default:
          break;
      }
    },

  }
}
</script>

<style scoped>
.topic-bar {
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: var(--bg);
  /* backdrop-filter: blur(20rem);
  -webkit-backdrop-filter: blur(20rem); */
  border-bottom: 0.1rem solid var(--border-grey);
}

.topic-tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: auto;
  background-color: var(--bg);
  margin-bottom: 0.1rem;
}

.topic-tab::-webkit-scrollbar {
  display: none;
}

.topic-button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 16px 0;
  transition: 0.3s;
  font-size: 1rem;
  color: var(--text);
  border-radius: 10px;
  flex: 1;
  position: relative;
  min-width: 45px;
}

.topic-button:hover {
  background-color: var(--hover-grey);
  position: relative;
}

.topic-button.active {
  color: var(--text);
  font-weight: 600;
}

.topic-button.active:hover {
  background-color: var(--hover-grey);
}

.topic-button.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 35%;
  right: 35%;
  height: 0.3rem;
  width: 30%;
  background: var(--main);
  border-radius: 0.3rem;
}

.banner-outer {
  border-bottom: 0.1rem solid var(--border-grey);
}

.banner-container {
  margin: 0 15px;
  padding: 15px 0;
  width: calc(100% - 30px);
  border-radius: 16px;
}

.banner-image {
  width: 100%;
  height: auto;
  border-radius: 16px;
  cursor: pointer;
}

@media (hover: none) {
  .topic-button:hover {
    background-color: inherit;
  }

  .topic-button.active:hover {
    background-color: inherit;
  }

}


@media only screen and (max-width: 700px) and (-webkit-device-pixel-ratio: 2),
       only screen and (max-width: 700px) and (-webkit-device-pixel-ratio: 3) {
  .topic-bar {
    position: static;
  }
}
</style>